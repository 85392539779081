import React, { useEffect, useState } from 'react'
import axios from "axios"
// import { Link } from "react-router-dom"

const TaskSchedule = () => {

  const [handleInput, setHandleInput] = useState({
    phone: "",
    title: "",
    description: "",
    scheduleTime: ""
  })

  const [getTask, setGetTask] = useState([])
  
  const [updtInptHandle, setUpdtInptHandle] = useState({
    id:"",
    phone: "",
    title: "",
    description: "",
    scheduleTime: ""
  });

  const getData = async () => {
    try {
      const response = await axios.get("https://tasknotifyapi.web2rise.in/api/tasks")
      console.log(response.data.data)
      if (response.data.data) {
        setGetTask(response.data.data)
      }
    } catch (error) {
      console.log(error, "Data is Not Getting")
    }
  }

  const formattedDate = dateGet => {
    const date = new Date(dateGet);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false, // Use 24-hour format
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate
  };

  const handleField = (e) => {
    setHandleInput({ ...handleInput, [e.target.name]: e.target.value })
  }

  const submitForm = async (e) => {
    e.preventDefault();
    const { phone, title, description, scheduleTime } = handleInput;
    try {
      const sendData = await axios.post("https://tasknotifyapi.web2rise.in/api/tasks", { phone, title, description, scheduleTime })
      if (sendData.data.status === "success") {
        getData()
        setHandleInput({phone: "", title: "", description: "", scheduleTime: ""})
      }
      // console.log(sendData)
    } catch (error) {
      console.log(error, "Data Not Post")
    }
  }
  
  const deleteData = async (index) => {
    const response = await axios.delete(`https://tasknotifyapi.web2rise.in/api/tasks/${index}`)
    if(response.data.message === "Task Deleted Successfully"){
      getData()
      console.log(response)
    }
  }

  const updtHandle = (e) => {
    setUpdtInptHandle({ ...updtInptHandle, [e.target.name]: e.target.value })
  }

  const updateData = async (e) => {
    e.preventDefault();
    console.log(updtInptHandle)
    const { id, phone, title, description, scheduleTime } = updtInptHandle;
    try {
      const sendData = await axios.put(`https://tasknotifyapi.web2rise.in/api/tasks/${id}`, { phone, title, description, scheduleTime })
      if (sendData.data.status === "success") {
        getData()
        setUpdtInptHandle({phone: "", title: "", description: "", scheduleTime: ""})
      }
      // console.log(sendData)
    } catch (error) {
      console.log(error, "Data Not Updated")
    }
  }

  const editData = (ediValue) => {
    const id = ediValue.id;
    const filteredData = getTask.find((val) => val.id === id);
    setUpdtInptHandle({ id:filteredData.id, phone: filteredData.phone, title: filteredData.title, description: filteredData.description, scheduleTime: filteredData.scheduleTime });
  };

  useEffect(() => {
    getData()
  }, []);


  return (
    <>
      <div className='display_Head'>
        <a className="create_btn" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Add New</a>
        {/* <input type="search" className="search_feild" placeholder="Search..." /> */}
        {/* <a className="logout_btn">Logout</a> */}
      </div>

      <ul className="user_manage">
        <li><b>Sr. no.</b></li>
        <li><b>Phone</b></li>
        <li><b>Title</b></li>
        <li><b>Description</b></li>
        <li><b>Date & Time</b></li>
        <li><b>Edit</b></li>
        <li><b>Delete</b></li>

        {getTask.map((val, i) => {
          return (<React.Fragment key={i}>
            <li>{1 + i}.</li>
            <li>{val.phone}</li>
            <li>{val.title}</li>
            <li>{val.description}</li>
            <li>{formattedDate(val.scheduleTime)}</li>

            <li><button data-bs-toggle="modal" data-bs-target="#exampleModalToggleedit" role="button" onClick={() => editData(val)}><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button></li>

            {getTask.length === 1 ? <li><div > <i style={{ cursor: "no-drop", color: "#ccc" }} className="fa fa-trash" aria-hidden="true" ></i></div></li> : <li><div onClick={() => deleteData(val.id)}> <i className="fa fa-trash" aria-hidden="true"></i> </div></li>}
          </React.Fragment>)
        })}
      </ul>



      <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalToggleLabel">Add Data</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">x</button>
              </div>
              <div className="modal-body user_add">
                <div className="form_input">
                  <label><b>Phone<span>*</span></b></label>
                  <input type="tel" name="phone" onChange={handleField} value={handleInput.phone} />
                </div>
                <div className="form_input">
                  <label><b>Title<span>*</span></b></label>
                  <input type="text" name="title" onChange={handleField} value={handleInput.title} />
                </div>
                <div className="form_input">
                  <label><b>Description<span>*</span></b></label>
                  <textarea type="textarea" name="description" onChange={handleField} value={handleInput.description} ></textarea>
                </div>
                <div className="form_input">
                  <label><b>Date & Time<span>*</span></b></label>
                  <input type="datetime-local" name="scheduleTime" onChange={handleField} value={handleInput.scheduleTime} />
                </div>
              </div>
              <div className="modal-footer user_addbtn">
                <button data-bs-dismiss="modal" aria-label="Close" onClick={submitForm} > Submit </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModalToggleedit" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">Update Data</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">x</button>
            </div>
            <div className="modal-body user_add">
              <div className="form_input">
                <label><b>Phone<span>*</span></b></label>
                <input type="tel" name="phone" onChange={updtHandle} value={updtInptHandle.phone} />
              </div>
              <div className="form_input">
                <label><b>Title<span>*</span></b></label>
                <input type="text" name="title" onChange={updtHandle} value={updtInptHandle.title} />
              </div>
              <div className="form_input">
                <label><b>Description<span>*</span></b></label>
                <textarea type="textarea" name="description" onChange={updtHandle} value={updtInptHandle.description} ></textarea>
              </div>
              <div className="form_input">
                <label><b>Date & Time<span>*</span></b></label>
                <input type="datetime-local" name="scheduleTime" onChange={updtHandle} value={updtInptHandle.scheduleTime} />
              </div>
            </div>
            <div className="modal-footer user_addbtn">
              <button data-bs-dismiss="modal" aria-label="Close" onClick={updateData}> Update </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TaskSchedule
