import './App.css';
import TaskSchedule from './component/TaskSchedule';
// import { BrowserRouter} from 'react-router-dom';

const App = () => {
  return (
    <>
        <TaskSchedule />
    </>
  );
}

export default App;
